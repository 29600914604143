import {
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable, Provider } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import {
  BehaviorSubject,
  Observable,
  catchError,
  filter,
  switchMap,
  take,
  tap,
  throwError
} from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { TokenStorageService } from 'src/app/services/token-storage.service';

const TOKEN_HEADER_KEY = 'Authorization';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshToken$: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private tokenStorageService: TokenStorageService,
    private authService: AuthService,
    private router: Router
  ) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let authReq = req;
    const token = this.tokenStorageService.getToken();
    if (token) {
      authReq = this.addTokenHeader(req, token);
    }

    return next.handle(authReq).pipe(
      catchError(error => {
        const isError = error instanceof HttpErrorResponse;
        const isStatus401 = error.status === 401;
        if (isError && isStatus401) {
          return this.handle401Error(authReq, next);
        }
        return throwError(() => error);
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshToken$.next(null);
      const refreshToken = this.tokenStorageService.getRefreshToken();
      if (refreshToken && refreshToken !== 'undefined') {
        return this.authService
          .refreshToken({
            refreshToken: refreshToken
          })
          .pipe(
            tap(({ accessToken }) => {
              this.isRefreshing = false;
              this.tokenStorageService.saveToken(accessToken);
              this.refreshToken$.next(accessToken);
            }),
            switchMap(({ accessToken }) => {
              return next.handle(this.addTokenHeader(request, accessToken));
            }),
            catchError(err => {
              this.isRefreshing = false;
              return throwError(() => err);
            })
          );
      } else {
        this.isRefreshing = false;
        this.logOut();
      }
    }
    return this.refreshToken$.pipe(
      filter(token => token !== null),
      take(1),
      switchMap(token => {
        return next.handle(this.addTokenHeader(request, token));
      })
    );
  }

  private addTokenHeader(request: HttpRequest<any>, token: string) {
    return request.clone({
      headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token)
    });
  }

  logOut() {
    localStorage.clear();
    window.location.href = environment.ADMIN_HUB_URL;
  }
}

export const AUTH_INTERCEPTOR_PROVIDER: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthInterceptor,
  multi: true
} as const;
