export const environment = {
  production: false,
  APP_NAME: 'Soctrip Admin',
  APP_TITLE: 'TMTravel',
  APP_LOGO: 'soctrip-logo',
  COMPANY_NAME: 'TMA Technology Group',
  SOCIAL_APP_URL: 'https://tmtrav-dev.tma-swerp.com',
  API_URL: 'https://api-dev-tmtrav.tma-swerp.com',
  REFRESH_TOKEN_KEY: 'refresh_token',
  USER_ROLE_KEY: 'user_role',
  REFRESH_TOKEN_EXPIRY_KEY: 'refresh_token_expiry_date',
  ACCESS_TOKEN_EXPIRY_KEY: 'refresh_token_expiry_date',
  AUTH_STATUS: 'auth_status_admin',
  USER_PROFILE: 'user_profile_admin',
  TOKEN_KEY: 'access_token_admin',
  TOKEN: 'token_admin',
  API_TIMEOUT: 10000,
  languages: ['en', 'vi'],
  ADMIN_HUB_URL: 'https://admin-dev.tma-swerp.com',
  ADMIN_URL: 'https://admin-tmtrav-dev.tma-swerp.com'
};
